/* 07.02. Section Title CSS */

.about-content-area .section-title-two .title {
    color: #030f27;
}

.section-title {
    position: relative;
    z-index: 99;

    margin-bottom: 40px;
    // Responsive
    @media #{$large-mobile}{
        margin-bottom: 30px;
    }

    & .title {
        font-size: 42px;
        line-height: 1;
        font-weight: 600;
        margin: 0;
        color: $heading-color;
        &.fz-34{
            font-size: 34px;
            max-width: 690px;
            margin: auto;
        }
        &.fz-32{
            font-size: 32px;
            line-height: 44px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 26px;
                line-height: 32px;
            }
            @media #{$large-mobile}{
                font-size: 24px;
                line-height: 30px;
                br{
                    display: none;
                }
            }
        }
        &.fz-28{
            font-size: 28px;
            line-height: 44px;
            // Responsive
            @media #{$tablet-device}{
                font-size: 26px;
                line-height: 32px;
            }
            @media #{$large-mobile}{
                font-size: 24px;
                line-height: 30px;
                br{
                    display: none;
                }
            }
        }
        
    }
    & .sub-title {
        font-size: 18px;
        line-height: 24px;
        font-weight: 500;

        display: block;
        max-width: 530px;
        margin: 0 auto;

        margin-top: 20px;

        color: $secondary-light;
        &.fz-18{
            font-size: 18px;
        }
    }
    &.color-light{
        .title{
            color: $white;
        }
        .sub-title{
            color: $white;
        }
    }

    @media #{$tablet-device} {
        & .title {
            font-size: 38px;
        }
    }
    @media #{$large-mobile} {
        & .title {
            font-size: 36px;
        }
        .sub-title{
            font-size: 18px;
        }
    }
    @media #{$extra-small-mobile} {
        & .title {
            font-size: 30px;
            &.fz-34{
                font-size: 26px;
            }
            &.fz-32{
                font-size: 26px;
            }
        }
        .sub-title{
            font-size: 16px;
        }
    }
}

.section-title-two{
    position: relative;
    z-index: 99;
    margin-bottom: 30px;
    // Responsive
    @media #{$desktop-device}{
        margin-bottom: 30px;
    }
    @media #{$large-mobile}{
        margin-bottom: 30px;
    }
    &.color-light{
        & .sub-title{
            color: $white-light;
        }
        & .title{
            color: $white;
        }
    }

    & .sub-title {
        font-size: 18px;
        line-height: 1.5;
        font-weight: 400;
        color: $body-color;
        position: relative;
        display: block;
        padding-left: 85px;
        margin-bottom: 25px;
        &::before{
            position: absolute;
            content: "";
            width: 70px;
            height: 2px;
            background-color: $primary;
            left: 0;
            bottom: 0;
        }
    }

    & .title {
        font-size: 32px;
        line-height: 42px;
        margin: 0;
        color: #fff;
        /*color: $heading-color;*/
    }

    // Responsive
    @media #{$desktop-device} {
        & .sub-title{
            font-size: 18px;
            margin-bottom: 20px;
        }
        & .title {
            font-size: 30px;
        }
    }
    @media #{$tablet-device} {
        & .sub-title{
            font-size: 18px;
            margin-bottom: 20px;
        }
        & .title {
            font-size: 38px;
        }
    }
    @media #{$large-mobile} {
        & .sub-title{
            font-size: 16px;
            margin-bottom: 10px;
        }
        & .title {
            font-size: 28px;
            line-height: 36px;
        }
    }
    @media #{$extra-small-mobile} {
        & .sub-title{
            font-size: 16px;
            padding-left: 65px;
            &::before{
                width: 60px;
            }
        }
        & .title {
            font-size: 26px;
            line-height: 36px;
            br{
                display: none;
            }
        }
    }
}


