/*----------------------------------------*/
/*  12. Footer CSS
/*----------------------------------------*/

.footer-bg-color{
    background-color: #ffffff;
    border-top: 1px solid #979797;
    box-shadow: -2px 0px 7px #ccc;
}

.footer-section {
    padding: 10px 0 0px;
    overflow: hidden;
    // Responsive
    @media #{$tablet-device}{
        padding: 80px 0 0px;
    }
    @media #{$large-mobile}{
        padding: 60px 0 0px;
    }
}
@media #{$tablet-device}{
    .col-xl-2.col-lg-2.col-md-4.col-sm-6.col-6.mb-6 {
        width: 50% !important;
    }
}
@media #{$large-mobile}{
    .col-xl-2.col-lg-2.col-md-4.col-sm-6.col-6.mb-6 {
        width: 50% !important;
    }
}

.copyright {
    font-size: 14px;
    line-height: 25px;
    text-align: center;
    padding: 20px 15px;
    border-top: 1px solid $gray-200;
    color: #748494;
    margin-bottom: 0;
}
.col-xl-2.col-lg-2.col-md-4.col-sm-6.col-6.mb-6{
    width: 14.66667%;
}
.col-xl-2.col-lg-2.col-md-4.col-sm-6.col-6.mb-6:nth-child(3) {
    width: 20%;
}
.footer-section .mb-lg-14 {
    margin-bottom: 0 !important;
}